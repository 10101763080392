import {
  en,
  fr,
  it,
  es,
  de,
  countries_en,
  countries_fr,
  countries_it,
  countries_es,
  countries_de,
} from '@youtoken/ui.lokalise-export';

export const defaultNS = 'translations' as const;
export const resources = {
  en: {
    translations: en,
    countries: countries_en,
  },
  fr: {
    translations: fr,
    countries: countries_fr,
  },
  it: {
    translations: it,
    countries: countries_it,
  },
  es: {
    translations: es,
    countries: countries_es,
  },
  de: {
    translations: de,
    countries: countries_de,
  },
} as const;

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)['en'];
  }
}
