import en from './locales/en.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import es from './locales/es.json';
import de from './locales/de.json';

import countries_en from './locales/countries/en.json';
import countries_fr from './locales/countries/fr.json';
import countries_it from './locales/countries/it.json';
import countries_es from './locales/countries/es.json';
import countries_de from './locales/countries/de.json';

export {en, fr, it, es, de};
export {countries_en, countries_fr, countries_it, countries_es, countries_de};
